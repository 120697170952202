/**
 * Log of messages that may be useful when debugging.
 */
export const logMessages: {
    message: string;
    timestamp: string;
    data?: any;
}[] = [];

function push(message: string, data: any = undefined) {
    if (logMessages.length > 10) {
        logMessages.pop();
    }
    //add newest to the beginning
    logMessages.unshift({
        message,
        timestamp: new Date().toISOString(),
        data
    });
}

export default {
    debug: function (message: string, data: any = undefined) {
        push(message, data);
        console.log(`%c🐛\t${message}`, "color: #88129d; font-weight:bold");
        if (data) console.log(data);
    },
    deprecated: function (message: string) {
        console.warn(`%c\t[Deprecated]\t${message}`, "color: Gray");
    },
    error: function (message: string, ...optionalArgsToLog: any[]) {
        push(message);
        console.log(`%c💥\t${message}`, "color: #88129d; font-weight:bold", ...optionalArgsToLog);
    },
    event: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Event]\t${message}`;
        push(result);
        console.log(`%c\t${result}`, "color: #ff1199", ...optionalArgsToLog);
    },
    info: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Info]\t${message}`;
        push(result);
        console.log(`%c\t${result}`, "color: DodgerBlue", ...optionalArgsToLog);
    },
    success: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Success]\t${message}`;
        push(result);
        console.log(`%c\t${result}`, "color: Green", ...optionalArgsToLog);
    },
    warn: function (message: string, ...optionalArgsToLog: any[]) {
        const result = `[Warning]\t${message}`;
        push(result);
        console.log(`%c\t${result}`, "color: Orange", ...optionalArgsToLog);
    }
};
