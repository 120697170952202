import {
    CheckboxFieldValue,
    DateFieldValue,
    EsignatureFieldValue,
    FieldValue,
    FileFieldValue,
    FormSchema,
    FormState,
    MultiselectFieldValue,
    QuestionSchema,
    QuoteBlockSchema,
    RadioListFieldValue,
    StepSchema,
    TextFieldValue,
    YesNoFieldValue
} from "../types";
import { isEmail } from "@common";
import { areAllConditionsSatisfied } from "./conditionHelpers";

export type ValidationResult = { isInvalid: boolean; message?: string };

export const isStepValid = (step: StepSchema, formState: FormState): boolean => {
    for (const block of step.blocks) {
        if (block.type === "html" || block.type === "quote") continue;

        if (areAllConditionsSatisfied(block.conditions ?? [], formState) && !isAnswerValid(block.question, formState)) {
            return false;
        }
    }
    return true;
};

export const areAllPreviousAnswersValid = (
    quoteBlockSchema: QuoteBlockSchema,
    formSchema: FormSchema,
    formState: FormState
) => {
    for (const step of formSchema.steps) {
        for (const block of step.blocks) {
            if (block === quoteBlockSchema) {
                return true;
            }

            if (
                block.type === "question" &&
                areAllConditionsSatisfied(block.conditions ?? [], formState) &&
                !isAnswerValid(block.question, formState)
            ) {
                return false;
            }
        }
    }
    return true;
};

const isAnswerValid = (question: QuestionSchema, formState: FormState): boolean => {
    switch (question.type) {
        case "address":
            if (!question.required) {
                return true;
            }
            return formState[question.dataMarkerAddress1].value.exists();
        default:
            return !validateField(question.required, formState[question.dataMarker].value).isInvalid;
    }
};

const getRequiredMessage = (value: FieldValue): string => {
    let prefix = "A value";
    if (value instanceof CheckboxFieldValue) {
        prefix = "A check";
    } else if (value instanceof DateFieldValue) {
        prefix = "A date";
        if (value.hasTime) prefix += " & time";
    } else if (value instanceof EsignatureFieldValue) {
        prefix = "A signature";
    } else if (value instanceof FileFieldValue) {
        prefix = "A file";
    } else if (value instanceof MultiselectFieldValue) {
        prefix = "At least one selection";
    } else if (value instanceof RadioListFieldValue) {
        prefix = "A selection";
    } else if (value instanceof TextFieldValue) {
        if (value.type === "email") {
            prefix = "An email address";
        } else if (value.type === "phone-number") {
            prefix = "A phone number";
        }
    } else if (value instanceof YesNoFieldValue) {
        return "Choose Yes or No";
    }

    return `${prefix} is required`;
};

export const validateField = (isRequired: boolean, value: FieldValue): ValidationResult => {
    if (isRequired && !value.exists()) {
        return { isInvalid: true, message: getRequiredMessage(value) };
    }

    if (value instanceof TextFieldValue) {
        if (value.type === "email") {
            return validateEmailField(value);
        }

        if (value.type === "phone-number") {
            return validatePhoneNumberField(value);
        }
    } else if (value instanceof EsignatureFieldValue) {
        return validateEsignatureField(value);
    } else if (value instanceof FileFieldValue) {
        return validateFileField(value);
    }

    return { isInvalid: false };
};

const validateEmailField = (value: TextFieldValue): ValidationResult => {
    if (!value.exists()) {
        return { isInvalid: false };
    }

    const message = "Please enter a valid email address";

    if (
        value.actualValue.indexOf(" ") > -1 ||
        value.actualValue.indexOf(",") > -1 ||
        value.actualValue.indexOf("@") !== value.actualValue.lastIndexOf("@")
    ) {
        return { isInvalid: true, message };
    }

    if (isEmail(value.actualValue)) {
        return { isInvalid: false };
    }
    return { isInvalid: true, message };
};

const validatePhoneNumberField = (value: TextFieldValue): ValidationResult => {
    if (!value.exists()) {
        return { isInvalid: false };
    }

    if (value.actualValue.length < 9 || value.actualValue.length > 20) {
        return { isInvalid: true, message: "The telephone number must be at least 9 and at most 20 characters long" };
    }

    // digits or the '+' symbol
    if (!/^[0-9 +-]*$/g.test(value.actualValue)) {
        return { isInvalid: true, message: "Please enter a valid telephone number" };
    }

    return { isInvalid: false };
};

const validateEsignatureField = (value: EsignatureFieldValue): ValidationResult => {
    if (!value.exists()) {
        return { isInvalid: false };
    }

    if (value.size < 20) {
        return { isInvalid: true, message: "Please draw a larger signature" };
    }

    return { isInvalid: false };
};

const validateFileField = (value: FileFieldValue): ValidationResult => {
    if (!value.exists()) {
        return { isInvalid: false };
    }

    //a subset of Bourg.Common.FileExtensionToolbox.AllowedExtensions
    const allowedExtensions = [
        "pdf",
        "docx",
        "doc",
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "xlsx",
        "xls",
        "txt",
        "rtf",
        "odt",
        "csv"
    ];

    for (const file of value.files) {
        const extension = (file.name ?? "").split(".").pop().toLowerCase();

        const allowed = allowedExtensions.find(x => x === extension);

        if (allowed) continue;

        return {
            isInvalid: true,
            message: `The file type '${extension}' for '${file.name}' is not supported. Only ${allowedExtensions.join(
                ", "
            )} file types are supported.`
        };
    }

    return { isInvalid: false };
};
