import Dictionary from "@common/Dictionary";

export default {
    replace: function <T>(array: T[], isToBeReplacedPredicate: (x: T) => boolean, newItem: T) {
        return array.map(x => {
            if (isToBeReplacedPredicate(x)) {
                return newItem;
            }
            return x;
        });
    },
    remove: function <T>(array: T[], item: T) {
        const i = array.indexOf(item);
        if (i > -1) {
            array.splice(i, 1);
        }
    },
    takeWhile: function <T>(iterable: Iterable<T>, predicate: (item: T) => boolean): T[] {
        const result: T[] = [];

        for (const item of iterable) {
            if (predicate(item)) {
                result.push(item);
                continue;
            }
            break;
        }

        return result;
    },
    unique: function <T>(array: T[]): T[] {
        const set = new Set<T>();
        const result: T[] = [];

        for (const item of array) {
            if (set.has(item)) {
                continue;
            }

            set.add(item);
            result.push(item);
        }

        return result;
    },
    uniqueBy: function <T1, T2>(array: T1[], fn: (x: T1) => T2): T1[] {
        const set = new Set<T2>();
        const result: T1[] = [];

        for (const item of array) {
            const key = fn(item);
            if (set.has(key)) {
                continue;
            }

            set.add(key);
            result.push(item);
        }

        return result;
    },
    groupBy: function <TItem, TProp>(
        array: ReadonlyArray<TItem>,
        func: (item: TItem) => TProp,
        equalityComparer?: (item1: TProp, item2: TProp) => boolean
    ) {
        const groups: { key: TProp; items: TItem[] }[] = [];

        for (const item of array) {
            const key = func(item);
            const group =
                equalityComparer == null
                    ? groups.find(g => g.key === key)
                    : groups.find(g => equalityComparer(g.key, key));

            if (group) {
                group.items.push(item);
            } else {
                groups.push({
                    key,
                    items: [item]
                });
            }
        }

        return groups;
    },
    sortDesc: function <T>(array: T[], selector: (x: T) => string | number) {
        return array.sort((a: T, b: T) => {
            const x = selector(a);
            const y = selector(b);

            if (typeof x === "string" && typeof y === "string") return y.localeCompare(x);
            if (typeof x === "number" && typeof y === "number") return y - x;

            return 0;
        });
    },
    sort: function <T>(array: T[], selector: (x: T) => string | number) {
        return array.sort((a: T, b: T) => {
            const x = selector(a);
            const y = selector(b);

            if (typeof x === "string" && typeof y === "string") return x.localeCompare(y);
            if (typeof x === "number" && typeof y === "number") return x - y;

            return 0;
        });
    },
    toGroupedDictionary: function <T>(array: T[], groupBy: keyof T & string): { [index: string]: T[] } {
        const dictionary: { [index: string]: T[] } = {};

        array.forEach(el => {
            const key = el[groupBy] as any as string;
            dictionary[key] = dictionary[key] || [];

            dictionary[key].push(el);
        });
        return dictionary;
    },
    toDictionary: function <T, T2 extends string | number>(array: T[], keySelector: (x: T) => T2): Dictionary<T> {
        const dictionary: Dictionary<T> = {};
        array.forEach(x => {
            dictionary[keySelector(x)] = x;
        });
        return dictionary;
    },
    range: (from: number, to: number) => {
        const result = [];
        if (from < to) {
            for (let i = from; i <= to; i++) {
                result.push(i);
            }
        } else if (from > to) {
            for (let i = to; i >= from; i--) {
                result.push(i);
            }
        }
        return result;
    }
};
