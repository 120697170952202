import { useOuterClick } from "@common";
import React, { useState } from "react";
import Icon from "@forms/styles/Icon";

interface ConfirmationButtonProps {
    color: string;
    label: string;
    requiresConfirmation: () => boolean;
    onClick: () => void;
    tooltipText: JSX.Element;
}

export default function ConfirmationButton(props: ConfirmationButtonProps) {
    const [clickCount, setClickCount] = useState(0);

    const [outClickRef] = useOuterClick<HTMLButtonElement>(() => {
        setClickCount(0);
    });

    const handleClick = () => {
        if (props.requiresConfirmation() && clickCount === 0) {
            setClickCount(1);
            return;
        }

        setClickCount(0);
        props.onClick();
    };

    return (
        <>
            <button
                ref={outClickRef}
                className="itf-button"
                type="button"
                onClick={handleClick}
                style={{ backgroundColor: props.color, borderColor: props.color }}>
                {props.label}
                <Icon icon="faRight" />
            </button>
            {props.requiresConfirmation && clickCount === 1 ? (
                <div className="itf-confirm-action">{props.tooltipText}</div>
            ) : null}
        </>
    );
}
