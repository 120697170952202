import { ParentAnchor, useEffectOnFirstRender } from "@common";
import apiEmbeddedInTouchForms from "@forms/helpers/apiEmbeddedInTouchForms";
import formStateFn from "@forms/helpers/formStateFn";
import { FormModel, FormState } from "@forms/types";
import React, { useContext, useState } from "react";
import Loader from "../Loader";
import SafeHtml from "../SafeHtml";
import FormsAppConfigContext from "@forms/state/FormsAppConfigContext";
import Conditional from "../Conditional";
import Quote from "../Quote";

interface Props {
    matterGuid: string;
    form: FormModel;
    formState: FormState;
    chainedFormUrl: string;
}

export default function FinalStep(props: Props) {
    const { config } = useContext(FormsAppConfigContext);
    const finalStep = props.form.schema.finalStep;
    const [compiledHtml, setCompiledHtml] = useState<string>(null);

    useEffectOnFirstRender(() => {
        if (finalStep.html.includes("{{")) {
            getCompiledHtml(props.matterGuid, props.form, props.formState, finalStep.html).then(result =>
                setCompiledHtml(result ?? finalStep.html)
            );
        } else {
            setCompiledHtml(finalStep.html);
        }
    });

    if (compiledHtml === null) {
        return <Loader />;
    }

    const hasChainedUrl = finalStep.chainedFormButton && props.chainedFormUrl;
    const chainedFormUrl = props.chainedFormUrl + (config.isInMatterModal ? "?isInMatterModal=true" : "");

    return (
        <div className="itf-body">
            <div className="itf-step">
                <h1 className="itf-step-heading" style={{ color: props.form.primaryColor }}>
                    {finalStep.label}
                </h1>
                <SafeHtml html={compiledHtml} />

                {finalStep.quote != null && (
                    <Conditional conditions={finalStep.quote.conditions} formState={props.formState}>
                        <Quote quoteBlockSchema={finalStep.quote} form={props.form} formState={props.formState} />
                    </Conditional>
                )}

                <div className="itf-step-actions">
                    {hasChainedUrl && (
                        <a
                            className="itf-button"
                            style={{
                                backgroundColor: props.form.tertiaryColor,
                                borderColor: props.form.tertiaryColor
                            }}
                            href={chainedFormUrl}>
                            {finalStep.chainedFormButton.text}
                        </a>
                    )}

                    {config.isInMatterModal && (
                        <ParentAnchor
                            className="itf-button"
                            style={{
                                backgroundColor: props.form.tertiaryColor,
                                borderColor: props.form.tertiaryColor
                            }}
                            href={`/co/matters/${props.matterGuid}${window.parent.location.hash}`}>
                            View Matter
                        </ParentAnchor>
                    )}
                </div>
            </div>
        </div>
    );
}

async function getCompiledHtml(matterGuid: string, form: FormModel, formState: FormState, htmlTemplate: string) {
    const { data } = formStateFn.toCompletionData(formState, form.schema);
    const result = await apiEmbeddedInTouchForms.EmbeddedInTouchForms.CompileHtmlBlocks({
        matterGuid,
        htmlTemplates: [htmlTemplate],
        data
    });

    if (!result.success) return null;

    return result.data[0];
}
