export default {
    asAToZLowercaseOnly: (input: string) => {
        return input
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^a-z-]/g, "");
    },
    asNumber: (input: string) => {
        if (!input) return 0;

        if (typeof input === "string") input = replaceAll(input, ",", "");
        let result = parseFloat((input || "0").toString());

        if (isNaN(result)) result = 0;

        return result;
    },
    fuzzyContains: (a: string, b: string) => {
        if (!a || a.length === 0) return false;

        if (!b || b.length === 0) {
            return true;
        }

        const formatTerm = (term: string) => {
            term = term.trim().toLowerCase();
            return term.replace(/[^a-zA-Z 0-9.]/g, " ");
        };

        a = formatTerm(a);
        b = formatTerm(b);

        const parts = b.split(" ");

        for (let i = 0; i < parts.length; i++) {
            if (a.indexOf(parts[i]) < 0) {
                return false;
            }
        }

        return true;
    },
    pluraliseBasedOnCount: (singular: string, plural: string, count: number) => {
        if (count === 1) return singular;
        return plural;
    },
    replaceAll,
    toPascalCase: (input: string) => {
        return input
            .split("-")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join("");
    },
    truncate: (input: string, length: number) => {
        if (!input) return "";

        if (input.length <= length) return input;

        return input.substring(0, length);
    },
    /**
     *
     * @param input @deprecated use urlComponent.toSafeString instead
     */
    urlSafe: (input: string) => {
        const noSpecial = input.replace(/[^a-zA-Z0-9 ]/g, "");
        const dashInsteadOfSpace = noSpecial.replace(/[^a-zA-Z0-9]/g, "-");
        //encode just in case
        return encodeURIComponent(dashInsteadOfSpace);
    },
    urlComponent: {
        toSafeString: (input: string) => {
            //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
            //encodeURIComponent escapes all characters except the following;
            //alphabetic, decimal digits, - _ . ! ~ * ' ( )
            const replacers = urlReplacements().map(
                ([search, replace]) =>
                    (value: string) =>
                        replaceAll(value, search, replace)
            );

            const output = replacers.reduce((acc, replacer) => replacer(acc), input);

            return encodeURIComponent(output);
        },
        fromSafeString: (input: string) => {
            const decoded = decodeURIComponent(input);

            const replacers = urlReplacements()
                .reverse()
                .map(
                    ([replace, search]) =>
                        (value: string) =>
                            replaceAll(value, search, replace)
                );

            return replacers.reduce((acc, replacer) => replacer(acc), decoded);
        }
    }
};

function replaceAll(value: string, search: string, replace: string) {
    return value.split(search).join(replace);
}

const urlReplacements: () => [charToEncode: string, encodedVersion: string][] = () => [
    [" ", "_"], // makes it easier to read
    [".", "_dot_"], // a '.' in part of the URL throws a server exception on direct page load
    ["&", "_and_"] //an encoded '&' throws a server exception on direct page load
];
