import { MutableRefObject, useEffect, useRef } from "react";
import { useEffectOnFirstRender } from "@common";

type OuterClickEventName = "mousedown" | "touchstart";

/**
 * Hook that listens clicks outside of reference element
 */
export default function <T extends HTMLElement, K extends HTMLElement = HTMLElement>(callback: () => void) {
    const callbackRef = useRef<() => void>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<T>(); // returned to client, who marks "border" element
    const excludeRef = useRef<K>(); // element to exclude from triggering an outer click

    // update cb on each render, so second useEffect has access to current value
    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffectOnFirstRender(() => {
        //determine which events to use
        const eventName: OuterClickEventName = "ontouchstart" in document.documentElement ? "touchstart" : "mousedown";

        document.addEventListener(eventName, handleClick);
        return () => document.removeEventListener(eventName, handleClick);

        function handleClick(e: MouseEvent | TouchEvent) {
            const target = e.target as HTMLElement;

            if (!innerRef.current || !callbackRef.current) return;

            const hasClickedOutside =
                !innerRef.current.contains(target) &&
                (excludeRef.current ? !excludeRef.current.contains(target) : true);

            if (hasClickedOutside) {
                callbackRef.current();
            }
        }
    });

    return [innerRef, excludeRef] as [MutableRefObject<T>, MutableRefObject<K>]; // convenience for client (doesn't need to init ref himself)
}
